import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const dev: IPartnerAppSettings = {
  flightingAppEnvironment: 'Dev',
  esxpBaseUrl: 'http://localhost:5000/',
  cdnBaseUrl: 'http://localhost:5003',
  serviceEndPoints: [
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatapipack.azurefd.net',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/lmt-coreapi-dev',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://esxp-sit.microsoft.com/rmavl',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://grmapiappsit.azurewebsites.net',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://esxp-sit.microsoft.com',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'http://localhost:7071',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://maintenancebackgroundfunction-uat-wus.azurewebsites.net',
    },
    {
      clientId: 'c5779b49-c238-45cb-8974-36958d4c7d96',
      serviceEndPoint: 'https://pslakedsdev.azurewebsites.net',
    }
  ],
  OneProfileBaseUrl: 'https://oneprofileuatapipack.azurefd.net',
  AvailabilityBaseUrl: 'https://esxp-sit.microsoft.com/rmavl',
  EmailNotificationBaseUrl: 'http://localhost:7071',
  TimeCoreBaseUrl:
    'https://professionalservicesint.microsoft.com/lmt-coreapi-dev',
  UpdateAutomatedForecastUrl: 'https://maintenancebackgroundfunction-uat-wus.azurewebsites.net',
  TimeSubscriptionKey: 'fd723c776fbd432fb12471dcf25d5c10',
  GrmApiBaseUrl: 'https://grmapiappsit.azurewebsites.net',
  GrmAzureSearchUrl: 'https://esxp-sit.microsoft.com',
  PsdlISDForecastBaseUrl :'https://pslakedsdev.azurewebsites.net',
  ProjectDetailsEndUrl: '/api/v1/Search/grmsit/grm-assignmentsearch-sit-index',
  TimeAssetsUrl: 'https://lmt-cdn-endpoint-dev.azureedge.net/assets/',
  RMResourceForecast: {
    NonProjectEventPriority: [35, 22, 16, 13, 33, 21, 37, 11, 4, 17],
    ClarityCode: '',
  },

  RMExperience: {
    batchSize: 5,
    numAvAPIParallelCalls: 4,
    RMPageSize: 10,
    isCosmosEndpoint: true,
  },
};
