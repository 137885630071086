export const Constants = {
  BlockedEventTypes: [1, 14, 30, 35],
  UrlConstants: {
    basicProfileUrl: '#/profile/{alias}/basic',
    resourceCalendarUrl: '#/resourcedashboard/{alias}/ResourceManager',
    mailUrlForAlias: 'mailto:{alias}@microsoft.com',
    mailConfirmationUrlForMCSAlias: '/api/UserAvailabilityConfirmation',
    updateAutomatedForecastForAlias: '/api/orchestrators/UpdateForecastFunction',
    teamsUrlForAlias:
      'https://teams.microsoft.com/l/chat/0/0?users={alias}@microsoft.com',
    projectDetailsUrl: '#/project/{projectIdentifier}/false/resourcemanager',
    requestDetailsUrl:
      '#/project/{projectIdentifier}/resource/{requestIdentifier}/false/{userRole}',
  },
  OneProfileConstants: {
    searchProfileUrl: '/api/v1/profile/searchprofile?searchText=',
    locationUrl: '/api/v1/master/country',
    stdTitleUrl: '/api/v1/master/businessroles',
    generateReporteesUrl:
      '/api/v1/profile/{0}/directreportees/profileskillassessment',
    generateBasicProfileUrl: '/api/v2.0/profile/{0}?IncludeInactive=false',
    generatePrimarySkillForAliasUrl: '/api/v1/profile/{0}/primaryskill',
  },
  TimeApiConstants: {
    bulkHoursEndpoint: '/api/v2/assignments/labor',
    submitterEndpoint: '/api/v1/labor/submitter/{userAlias}',
    assignmentEndpoint: '/api/v1/assignments/{userAlias}',
  },
  GrmApiConstants: {
    getDomainDataEndpoint: '/api/v1/Domain/GetDomainData',
    filtersPayload: {
      DomainDataLists: ['GeographyData'],
    },
    eventTypesPayload: {
      DomainDataLists: ['EventTypes'],
    },
  },
  AvailabilityConstants: {
    getWeeklyDistributionUrl: '/api/availability/WeeklyBookings',
    getBulkPjmForecastUrl: '/api/pjm/GetBulkForecast',
    getSAPForecast: '/api/pjm/GetForecast',
    getProjectHourDistribution: '/api/Resource/GetForecastVariance',
    getResourceForecast: '/api/Resource/GetForecast',
    getResourceNonProjectForecast: '/api/Resource/GetNonProjectForecast',
    getLastUpdatedDate: '/api/Resource/GetLastUpdatedDate',
    postResourceForecast: '/api/Resource/AddUpdateForecast',
    getCSAForecast: '/api/Resource/GetCSAForecast',
    getCalendarFreeHours: '/api/Insights/calendarInsights',
    getWorkingHoursPerWeek: '/api/Insights/workingHoursPerWeek',
    addUpdateCSAForecast: '/api/Resource/AddUpdateCSAForecast',
    getResourceTotalForecastHours: '/api/Resource/GetTotalForecastHours',
    getResourceForecastAccuracy: '/api/Insights/ForecastAccuracy',
    getPsdlISDForecastAccuracy : '/mwb/api/ISDResourceCalendarAccuracy?Alias={0}',
    loadMoreTreeKey: 'LOAD_MORE',
    createNonProjectDetailsEndUrl: '/api/Resource/CreateNonProjectForecast',
    deleteNonProjectDetailsEndUrl: '/api/Resource/DeleteNonProjectForecast/{0}',
    SyncActualHours: '/api/Resource/SyncActualHours',
    fetchAvailabilityConfigUrl: '/api/availabilityConfigurations',
    upsertAvailabilityConfigUrl: '/api/upsertAvailabilityConfiguration',
    getCalendarEventsUrl: '/api/Insights/GetMyCalendarEvents?{0}',
    getCalendarSettingsUrl: '/api/Insights/GetMyCalendarPermissions',
    addUpdateCEForecast: '/api/Talent/UpdateForecast',
    fetchFreeFlexHoursUrl: '/api/Talent/GetForecast',
    fetchRecommendedHoursUrl: '/api/Recommendation/GetRecommendedAvailability',
    forecastMetaDataUrl: '/api/Resource/GetForecastMetaData',
  },
  FlightingConstants: {
    ApplicationName: 'Resource Time Away',
    FXPApplicationName: 'Fxp',
    FXPFeatures: ['Talentwbflag'],
    Features: [
      'RMExperienceEnabled',
      'GeographyFilterEnabled',
      'RMResourceForecast',
      'RMResourceForecastExcluded',
      'RMResourceForecastBusinessRole',
      'ResourceTimeAwayCategories',
      'ResourceTimeAwayCountries',
      'Talentwbflag',
      'PJMToggleSupport',
      'HasAccessToAvailabilityForecastPage',
      'IsNewExperienceExclusiveCesRingUser',
      'IsCESCalendarGA',
      'CESCalendarEnabledForRoleId'
    ],
    CalendarFeatures: [
      'RMResourceForecast',
      'RMResourceForecastExcluded',
      'RMResourceForecastBusinessRole',
    ],
    FlightingParamsMapping: {
      Alias: 'alias',
      CountryCode: 'countryCode',
      ResourceCategory: 'resourceCategory',
      SDODomainId: 'SDODomainId',
      BusinessRoleId: 'businessRoleId',
    },
  },
  ProjectCategoryConstants: {
    SAPForecast: 'SAPForecast',
    VirtuosoForecast: 'VirtuosoForecast',
    Project: 'Project',
    NonProject: 'NonProject',
    PublicHoliday: 'PublicHoliday',
  },
  ExcelExportConstants: {
    EXCEL_TYPE:
      'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet;charset=UTF-8',
    EXCEL_EXTENSION: '.xlsx',
    excelfilename: 'snapshot',
  },
  GermanCouncilUser: 'GermanCouncilUser',
  OutLookCalendarView: 'https://outlook.office.com/mail/options/calendar/view',
  ServiceName: 'ResourceAvailability',
  ResourceForecastAppName: 'ResourceAvailability.ResourceForecast',
  ResourceAssignmentsAppName: 'ResourceAvailability.ResourceAssignments',
  ResourceForecastSharedComponentName: 'ResourceAvailability.AFluxScheduler',
  PowerBIAppName: 'PowerBISharedComponent',
};

export enum UserRole {
  RM = 'ResourceManager',
  FM = 'FunctionalManager',
  REQUESTOR = 'Requestor',
}

export class ResourceForecastFeatures {
  static startDateChangeCalendar = `${Constants.ResourceForecastAppName}.StartDateChangeCalendar`;
  static startDateChangeChevron = `${Constants.ResourceForecastAppName}.StartDateChangeChevron`;
  static PJMForecastToggle = `${Constants.ResourceForecastAppName}.PJMForecastToggle`;
  static routeToRequestDetails = `${Constants.ResourceForecastAppName}.RouteToRequestDetails`;
  static updateForecast = `${Constants.ResourceForecastAppName}.UpdateForecast`;
  static listAssignments = `${Constants.ResourceForecastAppName}.ListAssignments`;
  static viewLabor = `${Constants.ResourceForecastAppName}.ViewLabor`;
}

export class ResourceForecastSharedComponentFeatures {
  static startDateChangeCalendar = `${Constants.ResourceForecastSharedComponentName}.StartDateChangeCalendar`;
  static startDateChangeChevron = `${Constants.ResourceForecastSharedComponentName}.StartDateChangeChevron`;
  static listAssignments = `${Constants.ResourceForecastSharedComponentName}.ListAssignments`;
  static viewLabor = `${Constants.ResourceForecastSharedComponentName}.ViewLabor`;
}

export enum CSAComponentNumberOfWeeks {
  FOUR = 4,
  SIX = 6,
  EIGHT = 8,
}

export interface CSAComponentConfig {
  numberOfWeeks?: CSAComponentNumberOfWeeks;
}

export enum RecurrencePattern {
  none = 'None',
  daily = 'Daily',
  weekly = 'Weekly',
  monthly = 'Monthly',
}

export enum forecastUpdateApiErrorCodes {
  FcAPISuccess = 'C600',
  FcAPIPartialSuccess = 'C601',
  GRMDataUpdateError = 'GRMDataUpdateError',
  ForecastFetchError = 'ForecastFetchError',
  ForecastUpsertError = 'ForecastUpsertError',
  ValidationError = 'ValidationError',
  CosmosSuccessCode = 'C600',
  CosmosErrorCode = 'C601',
  CosmosThrottledCode = 'C602',
  SAPFetchErrorCode = 'SAP601',
  SAPFetchSuccessCode = 'SAP600',
}

export var GRMEVENTTYPES = [
  {
      "EventTypeId": 35,
      "EventTypeName": "Vacation",
      "EventColor": "#E48BB5",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": false,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": true,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": true,
      "PrivateText": "Do Not Contact-Not Working",
      "PrivateTextColor": "#E48BB5",
      "IsActive": true
  },
  {
      "EventTypeId": 22,
      "EventTypeName": "ROSS Pending from RC",
      "EventColor": "#FF8C00",
      "DisplayInEventList": true,
      "IsBusy": false,
      "IsCommentRequired": true,
      "IsApplicableToManagers": false,
      "IsApplicableToRCs": true,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 16,
      "EventTypeName": "Projects",
      "EventColor": "#A3B367",
      "DisplayInEventList": true,
      "IsBusy": false,
      "IsCommentRequired": true,
      "IsApplicableToManagers": false,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 13,
      "EventTypeName": "Other",
      "EventColor": "#E48BB5",
      "DisplayInEventList": true,
      "IsBusy": false,
      "IsCommentRequired": true,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 33,
      "EventTypeName": "Training Taken",
      "EventColor": "#55ABE5",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": true,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": true,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 21,
      "EventTypeName": "ROSS Pending",
      "EventColor": "#FF8C00",
      "DisplayInEventList": true,
      "IsBusy": false,
      "IsCommentRequired": true,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 37,
      "EventTypeName": "Appointment",
      "EventColor": "#E48BB5",
      "DisplayInEventList": true,
      "IsBusy": false,
      "IsCommentRequired": true,
      "IsApplicableToManagers": false,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": true,
      "PrivateText": "Contact Manager",
      "PrivateTextColor": "#E48BB5",
      "IsActive": true
  },
  {
      "EventTypeId": 11,
      "EventTypeName": "MS Event",
      "EventColor": "#E48BB5",
      "DisplayInEventList": true,
      "IsBusy": false,
      "IsCommentRequired": true,
      "IsApplicableToManagers": false,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 4,
      "EventTypeName": "DSE Contract Work",
      "EventColor": "#1C5850",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": true,
      "IsApplicableToManagers": false,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 17,
      "EventTypeName": "Public Holiday",
      "EventColor": "#E48BB5",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": false,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": true,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 1,
      "EventTypeName": "Bereavement",
      "EventColor": "#E48BB5",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": false,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": true,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": true,
      "PrivateText": "Do Not Contact-Not Working",
      "PrivateTextColor": "#E48BB5",
      "IsActive": true
  },
  {
      "EventTypeId": 3,
      "EventTypeName": "CritSit Only",
      "EventColor": "#5FBE7D",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": true,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 5,
      "EventTypeName": "Jury Duty",
      "EventColor": "#E48BB5",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": false,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": true,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": true,
      "PrivateText": "Do Not Contact-Not Working",
      "PrivateTextColor": "#E48BB5",
      "IsActive": true
  },
  {
      "EventTypeId": 6,
      "EventTypeName": "Leaving Role",
      "EventColor": "#000000",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": true,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 7,
      "EventTypeName": "Lieu Day (Comp)",
      "EventColor": "#E48BB5",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": true,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": true,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 8,
      "EventTypeName": "Local visits only",
      "EventColor": "#D2B48C",
      "DisplayInEventList": true,
      "IsBusy": false,
      "IsCommentRequired": true,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 9,
      "EventTypeName": "Managed IP Development",
      "EventColor": "#55ABE5",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": true,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 10,
      "EventTypeName": "Mentoring",
      "EventColor": "#55ABE5",
      "DisplayInEventList": true,
      "IsBusy": false,
      "IsCommentRequired": true,
      "IsApplicableToManagers": false,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": true,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 12,
      "EventTypeName": "On Call",
      "EventColor": "#33BAB1",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": true,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": true,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 14,
      "EventTypeName": "Parental Leave",
      "EventColor": "#E48BB5",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": false,
      "IsApplicableToManagers": false,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": true,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": true,
      "PrivateText": "Do Not Contact-Not Working",
      "PrivateTextColor": "#E48BB5",
      "IsActive": true
  },
  {
      "EventTypeId": 15,
      "EventTypeName": "Pre or Post Visit",
      "EventColor": "#A3B367",
      "DisplayInEventList": true,
      "IsBusy": false,
      "IsCommentRequired": true,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 18,
      "EventTypeName": "RaaS Only",
      "EventColor": "#254069",
      "DisplayInEventList": true,
      "IsBusy": false,
      "IsCommentRequired": false,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 19,
      "EventTypeName": "Reactive Only",
      "EventColor": "#5FBE7D",
      "DisplayInEventList": true,
      "IsBusy": false,
      "IsCommentRequired": true,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 27,
      "EventTypeName": "Scheduled Only",
      "EventColor": "#FECB6F",
      "DisplayInEventList": true,
      "IsBusy": false,
      "IsCommentRequired": false,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 30,
      "EventTypeName": "Sick",
      "EventColor": "#E48BB5",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": false,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": true,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": true,
      "PrivateText": "Do Not Contact-Not Working",
      "PrivateTextColor": "#E48BB5",
      "IsActive": true
  },
  {
      "EventTypeId": 55,
      "EventTypeName": "Success Activities",
      "EventColor": "#008080",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": true,
      "IsApplicableToManagers": false,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 31,
      "EventTypeName": "Training Delivery",
      "EventColor": "#55ABE5",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": true,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": true,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 32,
      "EventTypeName": "Training Developed",
      "EventColor": "#55ABE5",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": true,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": true,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 34,
      "EventTypeName": "Travel",
      "EventColor": "#ABABAB",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": false,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
      "EventTypeId": 36,
      "EventTypeName": "Weekend Off",
      "EventColor": "#E48BB5",
      "DisplayInEventList": true,
      "IsBusy": true,
      "IsCommentRequired": false,
      "IsApplicableToManagers": true,
      "IsApplicableToRCs": false,
      "IsSickVacationTraining": false,
      "IsSystemGenerated": false,
      "IsAllDayEvent": false,
      "IsSpecialtyRequired": false,
      "IsPrivate": false,
      "PrivateText": null,
      "PrivateTextColor": null,
      "IsActive": true
  },
  {
    "EventTypeId": 63,
    "EventTypeName": "Time Away",
    "EventColor": "#E48BB5",
    "DisplayInEventList": true,
    "IsBusy": true,
    "IsCommentRequired": false,
    "IsApplicableToManagers": true,
    "IsApplicableToRCs": false,
    "IsSickVacationTraining": true,
    "IsSystemGenerated": false,
    "IsAllDayEvent": false,
    "IsSpecialtyRequired": false,
    "IsPrivate": true,
    "PrivateText": "Do Not Contact-Not Working",
    "PrivateTextColor": "#E48BB5",
    "IsActive": true
  }
];
