import {
  IAppService,
  IStateConfig,
  IFxpAppContext,
  IServiceEndPoints,
  IRouteInfo,
  IPartnerBundle,
  IAppServiceWithSharedComponent,
  ISharedComponents,
  ISharedComponentConfig,
} from '@fxp/fxpservices';
import { StateParams } from '@uirouter/core';
import { appSettings } from './environments/common/appsettingsGenerator';
import { Constants } from './common/constants';
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class RMAvailabilityUXApp_routes
  implements IAppService, IAppServiceWithSharedComponent
{
  getRoutes(fxpContext: IFxpAppContext): IRouteInfo {
    const availabilityRoute: IStateConfig = {
      name: 'availability',
      url: '/availability?source',

generatedBundle: 'fe5d77a5a544-AvailabilityModule-Bundle',
      params: new StateParams({
        startDate: null,
        searchItem: null,
        searchByType: null,
      }),
      data: {
        headerName: 'Resource Assignments',
        breadcrumbText: 'Resource Assignments',
        pageTitle: 'Resource Assignments Page',
        partnerTelemetryName: Constants.ServiceName,
      },
      authorizationRules: {
        AllowedPermissions: {
          AuthorizeRolesAndResources: false,
          Roles: {
            Value: ['PS.ResourceManagement', 'PS.FunctionalManagement'],
          },
        },
      },
    };

    const ResourceAssignmentsForecastRoute: IStateConfig = {
      name: 'availabilityforecast',
      url: '/availabilityforecast?source&reason',

generatedBundle: 'fe5d77a5a544-AFluxSchedulerModule-Bundle',
      data: {
        headerName: 'Availability Forecast',
        breadcrumbText: 'Availability Forecast',
        pageTitle: 'Availability Forecast Page',
        partnerTelemetryName: Constants.ServiceName,
        appNameForTelemetry: Constants.ResourceForecastAppName, // appName which will be used to construct the feature name as appname.featurename format
        pageNameForTelemetry: 'ResourceForecast',
      },
    };

    const ResourceAssignmentsForecastViewRoute: IStateConfig = {
      name: 'availabilityforecastview',
      url: '/availabilityforecastview',

generatedBundle: 'fe5d77a5a544-ForecastViewParentModule-Bundle',
      data: {
        headerName: 'Availability Forecast View',
        breadcrumbText: 'Availability Forecast View',
        pageTitle: 'Availability Forecast Page',
        partnerTelemetryName: Constants.ServiceName,
        appNameForTelemetry: Constants.ResourceForecastAppName, // appName which will be used to construct the feature name as appname.featurename format
        pageNameForTelemetry: 'ResourceForecast',
      },
    };

    const CESCalendarRoute: IStateConfig = {
      name: 'cescalendar',
      url: '/cescalendar',

generatedBundle: 'fe5d77a5a544-CECalendarModule-Bundle',
      data: {
        headerName: 'CES Calendar',
        breadcrumbText: 'CES Calendar',
        pageTitle: 'CES Calendar Page',
        partnerTelemetryName: Constants.ServiceName,
        appNameForTelemetry: Constants.ResourceForecastAppName, // appName which will be used to construct the feature name as appname.featurename format
        pageNameForTelemetry: 'ResourceForecast',
      },
    };

    // Test Component enabled in lower environments
    let sharedComponentTestConfig: IStateConfig = undefined;
    if (
      ['dev', 'sit'].includes(
        appSettings().flightingAppEnvironment.toLocaleLowerCase()
      )
    ) {
      sharedComponentTestConfig = {
        name: 'ravailtest',
        url: '/ravailtest',

generatedBundle: 'fe5d77a5a544-TestModule-Bundle',
        data: {
          headerName: 'Availability Component Playground',
          breadcrumbText: 'Availability Component Playground',
          pageTitle: 'Availability Component Playground',
          partnerTelemetryName: Constants.ServiceName,
          appNameForTelemetry: Constants.ResourceForecastAppName, // appName which will be used to construct the feature name as appname.featurename format
          pageNameForTelemetry: 'ResourceForecast',
        },
      };
    }

    const routeInfo: IRouteInfo = {
      applicationName: 'RMAvailabilityUXApp',
      sharedBundles: [],
      adminRules: {
        ResourcePermissions: {
          AllResourcesMandatory: true,
          Value: [
            {
              Resource: 'FxPPlatform.ResourceManagement',
              Permissions: { Value: ['All'] },
            },
          ],
        },
      },
      routes: [
        availabilityRoute,
        ResourceAssignmentsForecastRoute,
        ResourceAssignmentsForecastViewRoute,
        CESCalendarRoute,
      ],
    };

    // Add test component in lower environments
    if (sharedComponentTestConfig) {
      routeInfo.routes.push(sharedComponentTestConfig);
    }

    return routeInfo;
  }

  getServiceEndPoints(): Array<IServiceEndPoints> {
    return appSettings().serviceEndPoints;
  }

  getBundles(): IPartnerBundle[] {
    return [];
  }

  getSharedComponentsInfo(): ISharedComponents {
    const availabilityGridSharedComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-AvailabilityGridModule-Bundle',
      componentDescription: 'This component renders hierarchial availability.',
      componentInputs: [
        ['showForecastHours', 'Show PJM forecast hours'],
        [
          'forceRefreshData',
          'Void observable to reapply filters in case data has changed',
        ],
        [
          'resourceInfoList',
          'Array of Basic Profile info to show on top level of tree',
        ],
        ['searchByType', 'Set Grid to empty when search type changed'],
        [
          'filterParams',
          'Array of IFilterParameters to filter attributes of resourceInfoList',
        ],
        ['curUserRole', 'RM or FM'],
      ],
      componentOutputs: [
        ['updateFilterBaseValues', 'Send profile attributes to master filter'],
      ],
      componentName: 'availabilityGrid',
    };
    const dataFilterComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-FilterModule-Bundle',
      componentDescription:
        'This component renders a filter on a list of data.',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'dataFilter',
    };
    const calendarTileComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-CalendarTileModule-Bundle',
      componentDescription:
        'This component renders a tile to manage calendar/forecasts',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'calendarTile',
    };
    const CSAQuickViewComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-CSAQuickViewModule-Bundle',
      componentDescription:
        'This component renders a quick tile to view forecasts',
      componentInputs: [['availability', 'CSA Availability forecast data']],
      componentOutputs: [],
      componentName: 'csaQuickView',
    };
    const InsightsAndCollaboratorsView: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-InsightsCollaboratorModule-Bundle',
      componentDescription:
        'This component renders a quick tile to view forecasts',
      componentInputs: [
        ['insightCollaboratorComponentConfig', 'Configuration object'],
        ['insightsObservable', 'Insights observable'],
        ['collaboratorsObservable', 'Collaborators observable'],
      ],
      componentOutputs: [],
      componentName: 'insightsandcollaborators',
    };
    const CSAAvailabilityViewComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-CSAAvailabilityViewModule-Bundle',
      componentDescription:
        'This component renders a quick tile to view availability',
      componentInputs: [['userAlias', 'User alias']],
      componentOutputs: [],
      componentName: 'csaAvailView',
    };

    const AvailabilitySyncComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-AvailabilityDataSyncModule-Bundle',
      componentDescription:
        'This component provides a quick option to sync past forecast with actual hours',
      componentInputs: [
        ['userAlias', 'User alias'],
        ['requestId', 'Resource Request Id'],
      ],
      componentOutputs: [],
      componentName: 'AvailDataSync',
    };

    const CSAForecastComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-CSAModule-Bundle',
      componentDescription: 'This component renders a table to view forecasts',
      componentInputs: [
        ['csaAvailabilitySubject', 'CSA Availability forecast data'],
      ],
      componentOutputs: [],
      componentName: 'csaForecast',
    };

    const ForecastViewComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-ForecastViewModule-Bundle',
      componentDescription: 'This component renders a table to view forecasts',
      componentInputs: [
        [
          'aFluxSchedulerConfig',
          'Config Object with properties (modes, viewStartDate, request, resource)',
        ],
      ],
      componentOutputs: [],
      componentName: 'AFlux-Scheduler',
    };

    const AFluxSchedulerComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-AFluxSchedulerWBModule-Bundle',
      componentDescription: 'This component renders a Forecast Scheduler',
      componentInputs: [
        [
          'aFluxSchedulerConfig',
          'Config Object with properties (modes, viewStartDate, request, resource)',
        ],
      ],
      componentOutputs: [
        ['RequestUpdates', 'Event emitted with updated requests'],
      ],
      componentName: 'AFlux-Scheduler-Parent',
    };

    const InfoPopUpComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-InfoPopUpModule-Bundle',
      componentDescription:
        'This component renders graphical info of project hours',
      componentInputs: [
        ['componentMode', 'Object to specify various component modes'],
        [
          'isDistributionDataAvailable',
          'Boolean value to indicate if hour distribution data is already present or not',
        ],
        [
          'distributionData',
          '[requested_hrs, actual_hrs, forecasted_hrs, remaining_hrs]',
        ],
        [
          'isForecastedHoursDataAvailable',
          'Boolean value to indicate if forecasted data is already present or not',
        ],
        ['forecastedHoursData', 'Forecasted hours for next 8 weeks'],
        ['scheduleEndDate', 'schedule end date'],
        ['resourceRequestID', 'Resource request ID'],
      ],
      componentOutputs: [],
      componentName: 'infoPopUp',
    };

    const PowerBiComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-PowerbiModule-Bundle',
      componentDescription: 'This component renders powerBi reports',
      componentInputs: [
        ['embededReportId', 'PowerBi report id'],
        ['embededDashboardId', 'PowerBi dashboard id'],
        ['PowerBiReportBaseUrl', 'PowerBi report base url'],
      ],
      componentOutputs: [],
      componentName: 'RenderPowerbiReports',
    };

    const PowerBiWrapperComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-PowerbiwrapperModule-Bundle',
      componentDescription:
        'This component gets workbench context and renders powerBi reports',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'RenderPowerbiwrapper',
    };

    const MessageWrapperComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-MessageWrapperModule-Bundle',
      componentDescription: 'This component renders message',
      componentInputs: [['messageConfig', 'Configuration object']],
      componentOutputs: [],
      componentName: 'MessageWrapperComponent',
    };

    const AFluxSummaryComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-AFluxSummaryModule-Bundle',
      componentDescription: 'This component shows forecast summary',
      componentInputs: [
        ['resourceAlias', 'Resource alias'],
        ['config', 'Configuration object'],
      ],
      componentOutputs: [['resourceDetail', 'Resource detail object']],
      componentName: 'AFluxSummaryComponent',
    };

    const userPreferenceComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-UserPreferenceModule-Bundle',
      componentDescription:
        'This component allows users to manage consent preferences',
      componentInputs: [['resourceAlias', 'Resource Alias']],
      componentOutputs: [['onClose', 'Callback on save/dismiss of component']],
      componentName: 'userPreference',
    };

    const AFluxSchedulerWrapperComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-AFluxSchedulerWrapperModule-Bundle',
      componentDescription:
        'This component is wrapper for all the forecast scheduler component instances',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'ForecastSchedulerWrapper',
    };

    const ceFreeFlexHeaderComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-CEFreeFlexHeaderModule-Bundle',
      componentDescription: 'This component is the header for ce calendar',
      componentInputs: [
        [
          'config',
          'Config Object with properties (alias, startDate, numOfColumns)',
        ],
      ],
      componentOutputs: [],
      componentName: 'CEFreeFlexHeaderComponent',
    };

    const ceFreeFlexWidgetComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-CEFreeFlexWidgetModule-Bundle',
      componentDescription:
        'This component is a widget displaying free flex hours and supports actions to edit',
      componentInputs: [
        ['prefetchBundleOnly', 'true to not render the component'],
        ['freeHours', 'Free hours'],
        ['flexHours', 'Flex hours'],
        ['isUserUpdated', 'is updated by user'],
        ['isReadonly', 'is editing disabled'],
        ['compactMode', 'for smaller containers'],
        ['alias', 'user alias'],
        ['date', 'date of forecast'],
      ],
      componentOutputs: [],
      componentName: 'CEFreeFlexWidgetComponent',
    };

    const availabilityTileComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-AvailabilityTileModule-Bundle',
      componentDescription: 'Availability tile on dashboard.',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'AvailabilityTile',
    };

    const clarityComp: ISharedComponentConfig = {

generatedBundle: 'fe5d77a5a544-ClarityModule-Bundle',
      componentDescription: 'Component to inject clarity script.',
      componentInputs: [],
      componentOutputs: [],
      componentName: 'Clarity',
    };

    return {
      appName: 'RMAvailabilityUXApp',
      components: [
        availabilityGridSharedComp,
        dataFilterComp,
        calendarTileComp,
        CSAQuickViewComp,
        CSAForecastComp,
        CSAAvailabilityViewComp,
        AvailabilitySyncComp,
        ForecastViewComp,
        AFluxSchedulerComp,
        InfoPopUpComp,
        PowerBiComp,
        PowerBiWrapperComp,
        InsightsAndCollaboratorsView,
        MessageWrapperComp,
        AFluxSummaryComp,
        userPreferenceComp,
        AFluxSchedulerWrapperComp,
        ceFreeFlexHeaderComp,
        ceFreeFlexWidgetComp,
        availabilityTileComp,
        clarityComp,
      ],
      disableSharing: false,
      sharedBundles: [],
    };
  }
  public getGeneratedBundles() {
    var baseUrl = '';
    var currentScriptUrl = document.currentScript['src'];
    if (currentScriptUrl.indexOf('exclude.inline.bundle') > 0) {
      currentScriptUrl = Error().stack.match(/(https?:\/\/[^ ]*)/g);
      baseUrl = currentScriptUrl
        .filter((item) => item.indexOf('localhost:5000') === -1)[0]
        .toLowerCase();
      baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    } else {
      baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/'));
    }
    if (baseUrl.endsWith("/")){
        baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    }
    return [
            {
                name: 'fe5d77a5a544-AvailabilityModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/AvailabilityModule.vendor.bundle.js',
                    baseUrl + '/AvailabilityModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-AFluxSchedulerModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/AFluxSchedulerModule.vendor.bundle.js',
                    baseUrl + '/AFluxSchedulerModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-ForecastViewParentModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/ForecastViewParentModule.vendor.bundle.js',
                    baseUrl + '/ForecastViewParentModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-CECalendarModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CECalendarModule.vendor.bundle.js',
                    baseUrl + '/CECalendarModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-TestModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/TestModule.vendor.bundle.js',
                    baseUrl + '/TestModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-AvailabilityGridModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/AvailabilityGridModule.vendor.bundle.js',
                    baseUrl + '/AvailabilityGridModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-FilterModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/FilterModule.vendor.bundle.js',
                    baseUrl + '/FilterModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-CalendarTileModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CalendarTileModule.vendor.bundle.js',
                    baseUrl + '/CalendarTileModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-CSAQuickViewModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CSAQuickViewModule.vendor.bundle.js',
                    baseUrl + '/CSAQuickViewModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-InsightsCollaboratorModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/InsightsCollaboratorModule.vendor.bundle.js',
                    baseUrl + '/InsightsCollaboratorModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-CSAAvailabilityViewModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CSAAvailabilityViewModule.vendor.bundle.js',
                    baseUrl + '/CSAAvailabilityViewModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-AvailabilityDataSyncModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/AvailabilityDataSyncModule.vendor.bundle.js',
                    baseUrl + '/AvailabilityDataSyncModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-CSAModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CSAModule.vendor.bundle.js',
                    baseUrl + '/CSAModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-ForecastViewModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/ForecastViewModule.vendor.bundle.js',
                    baseUrl + '/ForecastViewModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-AFluxSchedulerWBModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/AFluxSchedulerWBModule.vendor.bundle.js',
                    baseUrl + '/AFluxSchedulerWBModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-InfoPopUpModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/InfoPopUpModule.vendor.bundle.js',
                    baseUrl + '/InfoPopUpModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-PowerbiModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/PowerbiModule.vendor.bundle.js',
                    baseUrl + '/PowerbiModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-PowerbiwrapperModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/PowerbiwrapperModule.vendor.bundle.js',
                    baseUrl + '/PowerbiwrapperModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-MessageWrapperModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/MessageWrapperModule.vendor.bundle.js',
                    baseUrl + '/MessageWrapperModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-AFluxSummaryModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/AFluxSummaryModule.vendor.bundle.js',
                    baseUrl + '/AFluxSummaryModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-UserPreferenceModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/UserPreferenceModule.vendor.bundle.js',
                    baseUrl + '/UserPreferenceModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-AFluxSchedulerWrapperModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/AFluxSchedulerWrapperModule.vendor.bundle.js',
                    baseUrl + '/AFluxSchedulerWrapperModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-CEFreeFlexHeaderModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CEFreeFlexHeaderModule.vendor.bundle.js',
                    baseUrl + '/CEFreeFlexHeaderModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-CEFreeFlexWidgetModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/CEFreeFlexWidgetModule.vendor.bundle.js',
                    baseUrl + '/CEFreeFlexWidgetModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-AvailabilityTileModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/AvailabilityTileModule.vendor.bundle.js',
                    baseUrl + '/AvailabilityTileModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: 'fe5d77a5a544-ClarityModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/ClarityModule.vendor.bundle.js',
                    baseUrl + '/ClarityModule.bundle.js'
                ],
                sequentialLoading: true           
            },

    ]
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(RMAvailabilityUXApp_routes, 'RMAvailabilityUXApp');
